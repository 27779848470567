import deepmerge from 'deepmerge';
import { cn } from 'lib/utils/cn';
import { ReactNode } from 'react';
import { isNotUndefined, isString } from 'typesafe-utils';
import { isServer } from './isServer';

export * from './cn';
export * from './getChildrenCount';
export * from './getLabStylingClasses';
export * from './getPropStylesFactory';
export * from './url';
export * from './withBackgroundColor';
export * from './withFetchInterceptor';
export * from './withGridSystem';
export * from './withTextColorForBackground';

export const isDev = process.env.NODE_ENV === 'development' || isNotUndefined(process.env.VERCEL);

export function pascalCase(str: string) {
  if (!str) {
    return '';
  }

  const words = str.split(/[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]+/);
  const newWords = [];
  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i];
    if (currentWord === '') continue;
    newWords.push(currentWord[0].toUpperCase() + currentWord.slice(1));
  }
  return newWords.join('');
}

export function typeToComponent(type: string) {
  return pascalCase(type);
}

export function pathToComponent(path: string) {
  const pathFilter = ['dropZone', 'row', 'columns'];
  return typeToComponent(
    path
      .split('/')
      .splice(1)
      .filter((part: string) => !pathFilter.includes(part))
      .join('-'),
  );
}

export function calcMaxDimension(dimention: { height: number; width: number }, maxWidth: number) {
  if (dimention.width > maxWidth) {
    const cof = maxWidth / dimention.width;
    const newHeight = Math.round(cof * dimention.height);

    return {
      height: newHeight,
      width: maxWidth,
    };
  }

  return dimention;
}

export function jsonify(value: any) {
  return JSON.stringify(value, null, 2);
}

export function random1000() {
  return Math.floor(Math.random() * (9999 - 1000) + 1000);
}

export function getSite() {
  const site: string = process.env.OCELOT_SITE || '';
  if (!site) throw new Error('Missing env: OCELOT_SITE');
  return site;
}

export function getLabradorApiUrl() {
  const apiBaseUrl: string = removeTrailingSlash(process.env.OCELOT_LABRADOR_API || '');
  if (!apiBaseUrl) throw new Error('Missing env: OCELOT_LABRADOR_API');
  return apiBaseUrl;
}

export function getLabradorSessionId() {
  const id = process.env.OCELOT_LABRADOR_LABSESSID;
  if (!id) throw new Error('Missing env: OCELOT_LABRADOR_LABSESSID');
  return id;
}

export function getLabradorBackendUrl() {
  const backendUrl: string = removeTrailingSlash(process.env.OCELOT_LABRADOR_HOST || '');
  if (!backendUrl) throw new Error('Missing env: OCELOT_LABRADOR_HOST');
  return backendUrl;
}

export const getCacheInvalidationToken = (): string => {
  const token: string = process.env.OCELOT_REVALIDATION_TOKEN || '';
  if (!token) throw new Error('Missing env: OCELOT_REVALIDATION_TOKEN');
  return token;
};

export function getDomain(trailingSlash = true) {
  let domain: string = process.env.OCELOT_DOMAIN || '';

  if (!domain && isDev) {
    domain = `http://localhost:${process.env.PORT}`;
  }

  if (!domain) throw new Error('Missing env: OCELOT_DOMAIN');
  return removeTrailingSlash(domain) + (trailingSlash ? '/' : '');
}

export function getCommercialUrl() {
  const url = process.env.COMMERCIAL_CMS_URL;
  if (!url) {
    if (isDev) {
      return 'https://commercial-cms-dev.aller.se/';
    }
    throw new Error('Missing env: COMMERCIAL_CMS_URL');
  }

  return url;
}

export function getImageServer(trailingSlash = true) {
  const imageServer: string = process.env.OCELOT_LABRADOR_IMAGE_SERVER || '';
  if (!imageServer) throw new Error('Missing env: OCELOT_LABRADOR_IMAGE_SERVER');
  return removeTrailingSlash(imageServer) + (trailingSlash ? '/' : '');
}

export function getRecommendationMicroservice() {
  const recommendationMicroservice = process.env.OCELOT_RECOMMENDATION_MICROSERVICE;
  if (!recommendationMicroservice) throw new Error('Missing env: OCELOT_RECOMMENDATION_MICROSERVICE');

  return recommendationMicroservice;
}

export function getBackendImageServer(trailingSlash = true) {
  if (getLabradorBackendUrl().includes('labrador.aller.se')) {
    return `https://image-labrador.aller.se` + (trailingSlash ? '/' : '');
  }
  return `https://image-allas-stage.labradorcms.io` + (trailingSlash ? '/' : '');
}

export function widthToClass(width: { desktop?: number; mobile?: number }) {
  const classMap: { [w: string]: string } = {
    '8.333': 'w-1/12',
    '8.33': 'w-1/12',
    '16.667': 'w-2/12',
    '16.67': 'w-2/12',
    '25': 'w-3/12',
    '33.333': 'w-4/12',
    '33.33': 'w-4/12',
    '41.667': 'w-5/12',
    '41.67': 'w-5/12',
    '50': 'w-6/12',
    '58.333': 'w-7/12',
    '58.33': 'w-7/12',
    '66.667': 'w-8/12',
    '66.67': 'w-8/12',
    '75': 'w-9/12',
    '83.333': 'w-10/12',
    '83.33': 'w-10/12',
    '91.667': 'w-11/12',
    '91.67': 'w-11/12',
    '100': 'w-full',
  };

  const mobileClass = String(classMap[width.mobile || 100]);
  const desktopClass = String(classMap[width.desktop || 100]);

  return `${mobileClass} sm:${desktopClass}`;
}

export function ucfirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ucfirstAll(string: string) {
  return string
    .split(' ')
    .map((word) => ucfirst(word))
    .join(' ');
}

export function isBrowser() {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
}

export function removeTrailingSlash(str: string) {
  return str?.replace(/\/+$/, '') || str;
}

export function getViewportVisibilityClass({
  viewportVisibility = '',
  hideViewport = { mobile: false, desktop: false },
  display = 'block',
}: {
  viewportVisibility: string; // Lab3 property,
  hideViewport: { mobile: boolean; desktop: boolean }; //Lab4 property,
  display: string;
}): string {
  const hideMobileClass = `hidden sm:${display}`;
  const hideDesktopClass = 'sm:hidden';

  const hideMobile = hideViewport.mobile || viewportVisibility === 'desktop';
  const hideDesktop = hideViewport.desktop || viewportVisibility === 'mobile';

  return cn({
    [display]: hideDesktop || (!hideDesktop && !hideMobile),
    [hideDesktopClass]: hideDesktop,
    [hideMobileClass]: hideMobile,
  });
}

export const flipAspectRatio = (ar: number) => {
  let width = Math.pow(10, 4);
  let height = Math.round(ar * width);
  let denominator = 2;

  while (denominator < Math.min(height, width)) {
    if (width % denominator === 0 && height % denominator === 0) {
      width = width / denominator;
      height = height / denominator;
      denominator = 2;
    }

    denominator++;
  }

  return width / height;
};

export const truncToDecimal = (n: number, d: number) => Math.trunc(n * Math.pow(10, d)) / Math.pow(10, d);

export const isValidWidth = (width: number) => {
  const validWidths = [8.3, 16.6, 25.0, 33.3, 41.6, 50.0, 58.3, 66.6, 75.0, 83.3, 91.3, 100.0];

  return validWidths.includes(truncToDecimal(width, 1));
};

export const t = (textSize: { desktop: number; mobile: number }) => {
  return `t${textSize.desktop} tm${textSize.mobile}`;
};

export const isInRange = (x: number, min: number, max: number) => x >= min && x <= max;

export function slugify(section: string, options?: { replacement?: string }) {
  if (typeof section !== 'string') return '';

  return section
    ?.toLowerCase()
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^a-zA-Z0-9\-\s!?]+/g, '')
    .replace(/[?!/]/g, '')
    .replace(/\s+/g, options?.replacement ?? '-');
}

export function formatSeconds(seconds: number) {
  // TODO this could probably have been done better.
  const o = new Date(0);
  const p = new Date(seconds * 1000);
  const time = new Date(p.getTime() - o.getTime());
  const ms = time.getMilliseconds();
  const s = time.getSeconds();
  const m = time.getMinutes();
  const mSec = ms > 0 ? `.${ms}` : '';
  const sec = s > 0 ? `${s}${mSec}S` : '';
  const min = m > 0 ? `${m}M` : '';

  return `PT${min}${sec}`;
}

/**
 * Check if an email is valid against a regex
 *
 * @returns boolean
 */
export function isValidEmail(email: string) {
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
  );
  return emailRegex.test(email);
}

export function getSecondsDiff(date1: number, date2: number): number {
  const diffInMilliseconds = Math.abs(date2 - date1);
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  return diffInSeconds;
}

export const withSafeInnerHTML = (__html?: ReactNode) => {
  return __html && isString(__html)
    ? {
        dangerouslySetInnerHTML: { __html },
      }
    : {};
};

export const withNonHTMLChildren = (children?: ReactNode) => {
  return !isString(children) ? children : undefined;
};

export const deepCopy = <T>(toCopy: T): T => deepmerge({}, toCopy);

export const omitUndefined = <T extends Record<string, any>>(object?: T) =>
  Object.fromEntries(Object.entries(object ?? {}).filter(([_, value]) => isNotUndefined(value)));

export const mergeDefined = <T extends Record<string, any>, R = Partial<T>>(left?: T, right?: T) =>
  Object.assign(omitUndefined(left), omitUndefined(right)) as R;

/**
 * @deprecated Use `getBrandBy` instead
 */
export const siteToHost = (site: string): string => {
  const siteHostMap: { [key: string]: string } = {
    allas: 'allas.se',
    baaam: 'baaam.se',
    elle: 'elle.se',
    femina: 'femina.se',
    frida: 'frida.se',
    mabra: 'mabra.com',
    motherhood: 'motherhood.se',
    residence: 'residencemagazine.se',
    hant: 'hant.se',
    svenskdam: 'svenskdam.se',
  };
  return siteHostMap[site] || '';
};

/**
 * @deprecated Use `getBrandBy` instead
 */
export const siteIdToSite = (siteId: number): string => {
  const siteIdSiteMap: { [siteId: number]: string } = {
    2: 'allas',
    4: 'femina',
    5: 'motherhood',
    7: 'baaam',
    10: 'mabra',
    11: 'hant',
    12: 'svenskdam',
    14: 'elle',
    16: 'residence',
    85: 'frida',
  };
  return siteIdSiteMap[siteId] || '';
};

export const siteList = new Map([
  [2, 'Allas'],
  [4, 'Femina'],
  [5, 'Motherhood'],
  [10, 'MåBra'],
  [11, 'Hänt'],
  [12, 'Svensk Dam'],
  [14, 'Elle'],
  [16, 'Residence Magazine'],
]);

export const sitesUsingAdsHeightService = [
  'residence',
  'motherhood',
  'elle',
  'svenskdam',
  'hant',
  'allas',
  'femina',
  'mabra',
];

/**
 * Checks if a given character is a Swedish letter ('å', 'ä', and 'ö')
 *
 * @param {string} char - The character to check.
 * @returns {boolean} Returns true if the character is Swedish otherwise false.
 */
export const isSwedishLetter = (char: string) => {
  return ['å', 'ä', 'ö'].includes(char.toLowerCase());
};

export default function waitFor(predicate: any, { checkInterval = 50, timeout = 5000 } = {}) {
  let intervalId: any;
  return Promise.race([
    new Promise((_, reject) => timeout && setTimeout(reject, timeout)),
    new Promise((resolve) => {
      intervalId = setInterval(() => {
        const val = predicate();
        if (val) resolve(val);
      }, checkInterval);
    }),
  ]).finally(() => clearInterval(intervalId));
}

export const initAllerUserConsentQueue = () => {
  if (isServer()) return;
  // Init allerUserConsent queue if it doesn't exist
  const allerUserConsentDescriptor = Object.getOwnPropertyDescriptor(window?.allerUserConsent || {}, 'queue');
  if (!allerUserConsentDescriptor || allerUserConsentDescriptor.writable) {
    window.allerUserConsent = window.allerUserConsent || {};
    window.allerUserConsent.queue = window.allerUserConsent.queue || [];
  }
};

export const getAdsUniqueId = () => `${Math.random().toString(36).substring(2, 9)}`;

// Get random index between (0 and total)
export const getRandomIndex = (total: number = 0) => Math.floor(Math.random() * total);

export const showDebug = process.env.OCELOT_SHOW_DEBUG === 'true';
