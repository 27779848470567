export const withInstanceofProp = <Component extends React.FunctionComponent<any>>(
  component: Component,
  value: string,
) => Object.assign(component, { $$instanceof: Symbol.for(value) }) as Component;

export const withLabradorInstanceof = <Component extends React.FunctionComponent<any>>(component: Component) =>
  withInstanceofProp(component, `component.labrador`) as Component;

export const withStandaloneInstanceof = <Component extends React.FunctionComponent<any>>(component: Component) =>
  withInstanceofProp(component, `component.standalone`) as Component;

export const withSlotInstanceof = <Component extends React.FunctionComponent<any>>(component: Component) =>
  withInstanceofProp(component, `component.slot`) as Component;
